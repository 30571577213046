import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [3];

export const dictionary = {
		"/": [5],
		"/(app)/activity": [~6,[2]],
		"/api/salesforce/oauth": [~12],
		"/auth": [13],
		"/auth/redirect": [14],
		"/email": [15],
		"/(app)/forms": [~7,[2]],
		"/(form)/form/[slug]": [10,[3],[4]],
		"/(form)/form/[slug]/verify": [~11,[3],[4]],
		"/(app)/households": [~8,[2]],
		"/household/[slug]": [~16],
		"/(app)/meetings": [~9,[2]],
		"/settings": [~17],
		"/test": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';